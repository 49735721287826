<template>
  <div class="companyAddressInfo">
    <!-- 顶部导航 -->
    <commonNavBar :title="'供应商详情'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div
      class="info"
      v-watermark
    >
      <van-collapse
        v-model="activeNames"
        accordion
      >
        <van-collapse-item
          title="供应商基本信息"
          name="1"
        >
          <van-cell
            center
            title="单位名称:"
            :value="CompanyInfo.name || '--'"
          />
          <van-cell
            center
            title="单位状态:"
          >
            <template #default>
              <span
                style="color: #00a854"
                v-if="CompanyInfo.status === 0"
              >有效</span>
              <span
                style="color: #d9001b"
                v-else-if="CompanyInfo.status === 1"
              >无效</span>
              <span v-else>--</span>
            </template>
          </van-cell>
          <van-cell
            center
            title="供应商类型:"
            :value="CompanyInfo.typeName || '--'"
          />
          <van-cell
            center
            title="单位性质:"
            :value="CompanyInfo.natureName || '--'"
          />
          <van-cell
            center
            title="所属行业:"
            :value="CompanyInfo.industryName || '--'"
          />
          <van-cell
            center
            title="单位规模:"
            :value="CompanyInfo.scaleName || '--'"
          />
          <van-cell
            center
            title="省/市/区:"
            :value="(CompanyInfo.provinceName ? CompanyInfo.provinceName : '') +
              (CompanyInfo.cityName ? '/' + CompanyInfo.cityName : '') +
              (CompanyInfo.districtName
                ? '/' + CompanyInfo.districtName
                : '') || '--'
              "
          />
          <van-cell
            center
            title="单位地址:"
          >
            <template #default>
              <div
                style="color: #1990ff"
                @click="addressHandler(CompanyInfo.address)"
                v-if="CompanyInfo.address"
                class="ellipsis"
              >
                {{ CompanyInfo.address }}
              </div>
              <div v-else>--</div>
            </template>
          </van-cell>
          <van-cell
            center
            title="邮政编码:"
            :value="CompanyInfo.postCode || '--'"
          />
          <van-cell
            center
            title="联系人:"
            :value="CompanyInfo.contacts || '--'"
          />
          <van-cell
            title="联系电话:"
            center
          >
            <template #default>
              <p
                :style="{ color: '#1990ff' }"
                v-if="CompanyInfo.telephone"
                @click="callOut(CompanyInfo.telephone)"
              >
                {{ CompanyInfo.telephone }}
              </p>
              <p v-else>--</p>
            </template>
          </van-cell>
          <van-cell
            center
            title="单位传真:"
            :value="CompanyInfo.fax || '--'"
          />
          <van-cell
            center
            title="电子邮件:"
            :value="CompanyInfo.email || '--'"
          />
          <van-cell
            center
            title="更新时间:"
            :value="CompanyInfo.updateDatetime | dateFormat"
          />
          <userJump
            title="更新人:"
            :userList="CompanyInfo.updateBy"
          ></userJump>
        </van-collapse-item>
        <van-collapse-item
          title="资质信息"
          name="2"
        >
          <van-cell
            center
            title="统一社会信用代码:"
            :value="CompanyInfo?.qualifications?.unifiedSocialCreditCode ||
              '--'
              "
          />
          <van-cell
            center
            title="工商注册号:"
            :value="CompanyInfo?.qualifications?.registrationNumber ||
              '--'
              "
          />
          <van-cell
            center
            title="法定代表人:"
            :value="CompanyInfo?.qualifications?.legalPerson ||
              '--'
              "
          />
          <van-cell
            center
            title="注册资金:"
            :value="CompanyInfo?.qualifications?.registeredCapital
              ? CompanyInfo.qualifications.registeredCapital + '万人民币'
              : '--'
              "
          />
          <van-cell
            center
            title="主营业务:"
          >
            <template #default>
              <span
                style="color: #1990ff"
                @click="
                  businessRangeHandler(CompanyInfo.qualifications.businessRange)
                  "
                v-if="CompanyInfo?.qualifications?.businessRange"
              >点击查看</span>
              <span v-else>--</span>
            </template>
          </van-cell>
          <van-cell
            center
            title="相关资质:"
          >
            <template #default>
              <span
                style="color: #1990ff"
                @click="
                  relevantQualificationsHandler(
                    CompanyInfo.qualifications.relevantQualifications
                  )
                  "
                v-if="CompanyInfo?.qualifications?.relevantQualifications"
              >点击查看</span>
              <span v-else>--</span>
            </template>
          </van-cell>
          <van-cell
            center
            title="资质附件:"
          >
            <template #default>
              <span
                style="color: #1990ff"
                @click="
                  fileHandler(CompanyInfo.qualifications.qualificationsFiles)
                  "
                v-if="CompanyInfo?.qualifications?.qualificationsFiles"
              >点击查看</span>
              <span v-else>--</span>
            </template>
          </van-cell>
          <van-cell
            center
            title="营业执照:"
          >
            <template #default>
              <span
                style="color: #1990ff"
                @click="
                  qualifications(CompanyInfo.qualifications.businessLicensePath)
                  "
                v-if="CompanyInfo?.qualifications?.businessLicensePath"
              >点击查看</span>
              <span v-else>--</span>
            </template>
          </van-cell>
          <van-cell
            center
            title="更新时间:"
            :value="CompanyInfo?.qualifications?.updateDatetime | dateFormat
              "
          />
          <userJump
            title="更新人:"
            :userList="CompanyInfo?.qualifications?.updateBy"
          ></userJump>
        </van-collapse-item>
        <van-collapse-item
          title="其他信息"
          name="3"
        >
          <van-cell
            center
            title="合作时间:"
            :value="CompanyInfo?.other?.cooperativeTime || '--'
              "
          />
          <userJump
            title="提供人:"
            :userList="CompanyInfo?.other?.providerUser"
          ></userJump>
          <van-cell
            center
            title="营收规模(近2年):"
            :value="CompanyInfo?.other?.revenueScale || '--'
              "
          />
          <van-cell
            center
            title="主要客户类型:"
          >
            <template #default>
              <span
                style="color: #1990ff"
                @click="customerTypeHandler(CompanyInfo.other.customerType)"
                v-if="CompanyInfo?.other?.customerType"
              >点击查看</span>
              <span v-else>--</span>
            </template>
          </van-cell>
          <van-cell
            center
            title="标志性项目:"
          >
            <template #default>
              <span
                style="color: #1990ff"
                @click="iconicItemsHandler(CompanyInfo.other.iconicItems)"
                v-if="CompanyInfo?.other?.iconicItems"
              >点击查看</span>
              <span v-else>--</span>
            </template>
          </van-cell>
          <van-cell
            center
            title="更新时间:"
            :value="CompanyInfo?.other?.updateDatetime | dateFormat
              "
          />
          <userJump
            title="更新人:"
            :userList="CompanyInfo?.other?.updateBy"
          ></userJump>
        </van-collapse-item>
      </van-collapse>
    </div>
    <!-- 资质附件弹框 -->
    <van-dialog
      v-model="fileShow"
      title="资质附件"
      show-cancel-button
      :show-confirm-button="false"
      cancel-button-color="#1989FA"
      class="fileDialog"
    >
      <van-cell
        :title="`${item.fileName}.${item.ext}`"
        title-style="color:#1989FA"
        v-for="(item, index) in fileArr"
        :key="index"
        @click="
          fileClick(
            item.ext,
            item
          )
          "
        :url="item.ext === 'pdf' ? '/api/file' + item.originalPath : ''"
      />
    </van-dialog>
  </div>
</template>

<script>
import { getSupplierInfo } from '@/api/supplier'
import dayjs from 'dayjs'
import { Dialog, ImagePreview } from 'vant'
import wx from 'weixin-js-sdk'
import index from '@/mixins'

export default {
  name: 'companyAddressInfo',
  mixins: [index],
  data() {
    return {

      activeNames: '1',
      CompanyInfo: {},
      loadingShow: false,
      fileShow: false,
      fileArr: []
    }
  },
  async created() {
    this.loadingShow = true
    try {
      const { data } = await getSupplierInfo(this.$route.params.id)
      if (data.data) {
        this.CompanyInfo = data.data
        wx.onMenuShareAppMessage({
          title: this.CompanyInfo.name, // 分享标题
          desc: `所属省份:${this.CompanyInfo.provinceName ? this.CompanyInfo.provinceName : '--'
            }\n类型:${this.CompanyInfo.typeName ? this.CompanyInfo.typeName : '--'
            }\n合作时间:${this.CompanyInfo?.other?.cooperativeTime
              ? dayjs(this.CompanyInfo.other.cooperativeTime).format('YYYY-MM-DD')
              : '--'
            }`, // 分享描述
          link: '', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: 'https://is.shfas96119.com/word/FAS.png', // 分享图标
          enableIdTrans: 1, // 是否开启id转译，不填默认为0
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          error: function (res) {
            alert('暂无分享权限')
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          }
        })
        this.loadingShow = false
      } else {
        this.loadingShow = false
        if (data?.status === 'E00030007') {
          this.$toast.fail({
            message: '暂无相应权限,请联系管理员!',
            duration: 500,
            onOpened: () => {
              this.$router.replace('/')
            }
          })
          return
        }
        this.$toast.fail({
          message: '该供应商已被删除,请联系管理员!',
          duration: 500
        })
      }
    } catch (error) {
      this.loadingShow = false
      if (
        error?.response?.status === 401
      ) {
        return
      }
      if (error.message.includes('timeout')) {
        this.$toast.fail({
          message: '网络不好，请稍后再试!',
          duration: 500
        })
        return
      }
      if (error?.response?.data?.status === 'E00030050') {
        this.$toast.fail({
          message: '该供应商已被删除,请联系管理员!',
          duration: 500
        })
        return
      }
      if (error?.response?.data?.status === 'E00030007') {
        this.$toast.fail({
          message: '暂无相应权限,请联系管理员!',
          duration: 500,
          onOpened: () => {
            this.$router.replace('/')
          }
        })
        return
      }
      this.$toast.fail({
        message: '当前网络异常，请稍后再试!!',
        duration: 500
      })
    }
  },
  methods: {
    // 呼叫手机
    callOut(val) {
      Dialog.confirm({
        title: '信息确认',
        message: `确认呼叫${val}?`
      })
        .then(() => {
          window.location.href = `tel:${val}`
        })
        .catch(() => {
          // on cancel
        })
    },
    // 报废原因弹框
    scrapReasonHandler(text) {
      Dialog.alert({
        title: '不合格原因',
        message: text,
        confirmButtonColor: '#1989FA'
      }).then(() => {
        // on close
      })
    },
    // 地址弹框
    addressHandler(text) {
      this.addressClickTo(this.CompanyInfo.provinceName +
        this.CompanyInfo.cityName +
        this.CompanyInfo.districtName + this.CompanyInfo.address, this.CompanyInfo.name)
      Dialog.confirm({
        title: '单位地址',
        message: text,
        confirmButtonColor: '#1989FA',
        confirmButtonText: '去定位',
        beforeClose: this.beforeClose
      })
        .then(() => {
          // on close
        })
        .catch(() => {
          // on cancel
        })
    },
    // 营业执照预览
    qualifications(path) {
      ImagePreview({
        images: ['/api/file/' + path],
        closeable: true
      })
    },
    // 主营业务展示
    businessRangeHandler(text) {
      Dialog.alert({
        title: '主营业务',
        message: text,
        confirmButtonColor: '#1989FA'
      }).then(() => {
        // on close
      })
    },
    // 主要客户类型展示
    customerTypeHandler(text) {
      Dialog.alert({
        title: '主要客户类型',
        message: text,
        confirmButtonColor: '#1989FA'
      }).then(() => {
        // on close
      })
    },
    // 标志性项目展示
    iconicItemsHandler(text) {
      Dialog.alert({
        title: '标志性项目',
        message: text,
        confirmButtonColor: '#1989FA'
      }).then(() => {
        // on close
      })
    },
    // 相关资质展示
    relevantQualificationsHandler(text) {
      Dialog.alert({
        title: '相关资质',
        message: text,
        confirmButtonColor: '#1989FA'
      }).then(() => {
        // on close
      })
    },
    // 资质附件弹框
    fileHandler(newArr) {
      this.fileArr = newArr
      this.fileShow = true
    },
    // 资质附件点击
    fileClick(ext, itemore) {
      if (ext !== 'pdf') {
        const newArr = this.fileArr
          .filter(item => {
            return item.ext !== 'pdf'
          })
          .map(item1 => {
            return '/api/file' + item1.originalPath
          })
        ImagePreview({
          images: [...newArr],
          closeable: true,
          startPosition: this.fileArr
            .filter(itemFirst => {
              return itemFirst.ext !== 'pdf'
            }).findIndex(itemKey => JSON.stringify(itemKey) === JSON.stringify(itemore))
        })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.companyAddressInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .info {
      height: calc(100% - 46px);
      overflow: auto;

      .van-collapse {
        background-color: transparent;

        .van-collapse-item {
          background-color: transparent;

          .van-collapse-item__title {
            background-color: white;
            font-weight: 700;
          }

          .van-collapse-item__wrapper {
            background-color: transparent;

            .van-collapse-item__content {
              background-color: transparent;
            }
          }
        }
      }
    }

    .fileDialog {
      padding: 10px;
      padding-bottom: 0;
      max-height: 80%;
      overflow: auto;

      .van-dialog__header {
        text-align: left;
        padding: 0;
      }
    }
  }
}
</style>
